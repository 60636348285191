<template>
  <div class="tc-psychological_census">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="generalinvestigationBtn" @click="showAddCensus">
        <img src="../../../assets/images/generalinvestigationBtn.png" alt="" />
      </div>
      <div class="del" @click="delDatas">
        <img src="../../../assets/images/del.png" alt="" />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt="" />
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border=""
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_title"
          label="普查名称"
          width="250"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_title"
          label="开始日期"
          width="250"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_title"
          label="截止日期"
          width="250"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_title"
          label="普查状态"
          width="250"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="goReport(scope.row.id)"
              >删除此普查</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 增加普查模态框 -->
    <el-dialog
      title="增加普查"
      :close-on-click-modal="false"
      :visible.sync="addCensus"
      width="50%"
      center
    >
      <el-form ref="form" :model="form" label-width="80px" size="mini">
        <el-form-item label="普查名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="日期范围">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="普查量表">
          <!-- 查询 -->
          <div class="query">
            <el-form :inline="true" :model="form" size="mini">
              <el-form-item>
                <el-input
                  v-model="form.scaleName"
                  placeholder="量表名称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="queryScale()"
                  >量表查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="checkAll(1)"
                  >全选或取消所有量表</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="checkAll(2)"
                  >全选或取消测评人查看结果</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- 所有量表 -->
          <div class="table" @change="handleCheckAllChange">
            <table>
              <tr>
                <th>量表名称</th>
                <th>使用该量表</th>
                <th>允许测评人看测评结果</th>
              </tr>
              <!-- ----------------------- -->
              <template v-for="item in scaleData">
                <tr
                  class="title"
                  :key="item.id"
                  v-if="item.status_measure.length > 0"
                >
                  <td colspan="3">{{ item.classify_name }}</td>
                </tr>
                <tr v-for="item2 in item.status_measure" :key="item2.id">
                  <td>{{ item2.measure_title }}</td>
                  <td>
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox :label="item2.id" name="a"></el-checkbox>
                    </el-checkbox-group>
                  </td>
                  <td>
                    <el-checkbox-group v-model="checkList2">
                      <el-checkbox :label="item2.id" name="b"></el-checkbox>
                    </el-checkbox-group>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCensus = false">取 消</el-button>
        <el-button type="primary" @click="addCensus = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      form: {
        date: "",
        name: "",
        measure: [],
      },
      addCensus: false,
      // 表单
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 所有量表-全选控制
      checkAll1: 1,
      checkAll2: 1,
      multipleSelection: [],
    };
  },
  created() {
    this.loadScaleData();
  },
  methods: {
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
      //console.log(p);
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        this.scaleData1 = res.data.data;
      } else {
        return;
      }
    },
    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      if (val.target.name === "a") {
        var obj = { measure_id: a, show_result: "" || 0 };
        var tmp = this.form.measure.some(function (item) {
          return item.measure_id === a;
        });
        if (tmp) {
          this.form.measure = this.form.measure.filter((v) => {
            return v.measure_id != val.target.value;
          });
        } else {
          this.form.measure.push(obj);
        }
      }

      if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        var index = this.form.measure.findIndex((item) => {
          return item.measure_id === a;
        });
        if (this.form.measure[index] && index != -1) {
          if (this.form.measure[index].show_result === 0) {
            this.form.measure[index].show_result = 1;
          } else {
            this.form.measure[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      }
    },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res === 1) {
        if (this.checkAll1 === 1) {
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { measure_id: v.id, show_result: 1 };
              this.form.measure.push(obj);
            });
          });
          this.checkAll1 = 0;
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.form.measure = [];
          this.checkAll1 = 1;
        }
      } else {
        if (this.checkAll2 === 1) {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 0;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 量表查询
    queryScale() {
      var str = this.form.scaleName;
      if (str === "") {
        this.scaleData = this.scaleData1;
        return;
      }
      var obj = [{ classify_name: "查询结果", id: 1, status_measure: [] }];
      for (let k in this.scaleData1) {
        this.scaleData1[k].status_measure.forEach((v) => {
          if (v.measure_title.indexOf(str) != -1) {
            obj[0].status_measure.push(v);
          }
        });
      }
      this.scaleData = obj;
    },
    // 显示增加普查模态框
    showAddCensus() {
      this.addCensus = true;
    },
    // 全选删除
    delDatas() {
      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning",
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = this.multipleSelection;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less">
.tc-psychological_census {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .generalinvestigationBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 模态框
  .el-dialog__wrapper {
    .table {
      // width: 850px;
      height: 300px;
      overflow: auto;
      border: 1px #cccccc solid;
      margin-bottom: 20px;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }

      table {
        width: 100%;
        text-align: center;
        overflow: auto;
        tr,
        th {
          height: 20px;
          padding: 0;
          font-weight: 300;
          font-size: 14px;
        }
        tr {
          td {
            &:nth-child(1) {
              text-align: left;
            }
          }
        }
        th {
          height: 30px;
          font-size: 15px;
          background: rgb(224, 245, 250);
        }
        .title {
          background: rgb(224, 245, 250);
          text-align: left;
          font-weight: 700;
          font-size: 15px;
        }
        .el-checkbox__label {
          display: none;
        }
      }
    }
  }
}
</style>